@media screen and (max-width: 1000px) {
    .career-box {
        flex-direction: column;
    }
    .career-box > div {
        width: 100% !important;
        margin: 1rem 0;
    }
    #about-me  .content {
        flex-direction: column;
    }
    #about-me  .content > div {
        width: 100% !important;
    }
    #about-me  .content .buttons-container {
        flex-direction: row !important;
        margin-bottom: 2.5rem;
    }
    #about-me  .content .buttons-container > a {
        margin-right: 1rem;
    }
    #testimonials .testimonals-content-container {
        padding: 5rem !important;
    }
    #testimonials .testimonals-content-container h4 {
        margin: 1rem;
    }
    #home .fw-container {
        height: unset !important;
        min-height: 100vh !important;
    }
}


.career-box > div {
    width: 32%;
    margin: 1rem 0;
}

@media screen and (max-width: 768px) {
    #footer footer > div {
        flex-direction: column;
    }
    #footer footer > div > div {
        margin: 1rem 0 !important;
    }
    html {
        font-size: 10px !important;
    }
    .testimonals-content-container {
        flex-direction: column;
    }
}
